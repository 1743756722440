import { ComponentType, FC, lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Loading from "../components/common/Loading";
import { IpropsAppRoute, IpropsAuthRoute } from "../types/types";

// component

const HomePage: ComponentType = lazy(
  () => import("../pages/HomeRoute/bottomTabs/home/Home")
);
const Trackers: ComponentType = lazy(
  () => import("../pages/HomeRoute/bottomTabs/Tracker/Trackers")
);
const EasyAccess: ComponentType = lazy(
  () => import("../pages/HomeRoute/bottomTabs/Tracker/EasyAccess")
);
const Guide: ComponentType = lazy(
  () => import("../pages/HomeRoute/bottomTabs/guide/Guide")
);
const NotFound: ComponentType = lazy(
  () => import("../pages/HomeRoute/common/NotFound")
);

const Auth: ComponentType = lazy(() => import("../pages/AuthRoute/auth/Auth"));
const Login: ComponentType = lazy(
  () => import("../pages/AuthRoute/login/Login")
);
const FingerPrint: ComponentType = lazy(
  () => import("../pages/AuthRoute/fingerPrint/FingerPrint")
);
const Register: ComponentType = lazy(
  () => import("../pages/AuthRoute/register/Register")
);
const ForgetPassword: ComponentType = lazy(
  () => import("../pages/AuthRoute/forgetPassword/ForgetPassword")
);
const RenewPassword: ComponentType = lazy(
  () => import("../pages/AuthRoute/renewPassword/RenewPassword")
);
const VerifyPassword: ComponentType = lazy(
  () => import("../pages/AuthRoute/verifyPassword/VerifyPassword")
);
const EditProfile = lazy(
  () => import("../pages/HomeRoute/menubar/EditProfile")
);
const EditPassword = lazy(
  () => import("../pages/HomeRoute/menubar/EditPassword")
);
const PeriodicServices = lazy(
  () => import("../pages/HomeRoute/menubar/PeriodicServices")
);
const SupportCall = lazy(
  () => import("../pages/HomeRoute/menubar/SupportCall")
);
const RecieveMessage = lazy(
  () => import("../pages/HomeRoute/bottomTabs/Tracker/RecieveMessage")
);

const TrackerSettings = lazy(
  () => import("../pages/HomeRoute/bottomTabs/Tracker/TrackerSettings")
);

const TransactionList = lazy(
  () => import("../pages/HomeRoute/menubar/TransactionList")
);
const AppSettings = lazy(
  () => import("../pages/HomeRoute/menubar/AppSettings")
);

const RenewSubscription = lazy(
  () => import("../pages/HomeRoute/menubar/RenewSubscription")
);

const UserManagementPage = lazy(
  () => import("../pages/HomeRoute/menubar/userManagement")
);

const GeoMapPage = lazy(
  () => import("../pages/HomeRoute/bottomTabs/home/GeoMap")
);

const GeoMapEditPage = lazy(
  () => import("../pages/HomeRoute/bottomTabs/home/GeoMapEdit")
);

const GeoListPage = lazy(
  () => import("../pages/HomeRoute/bottomTabs/home/GeoList")
);

const AppLayout = lazy(() => import("../layouts/appLayout"));

// protected route
const AppRoute: FC<IpropsAppRoute> = ({
  children,
  hasBottomMenu,
  title,
  backUrl,
  hasHeader,
  fullScreen,
}) => {
  const token = localStorage.getItem("token");

  const secondToken =
    localStorage.getItem("biometricLogin") &&
    !localStorage.getItem("touchToken");

  return token ? (
    secondToken ? (
      <Navigate to="/" replace />
    ) : fullScreen ? (
      children
    ) : (
      <AppLayout
        backUrl={backUrl}
        title={title}
        hasBottomMenu={hasBottomMenu}
        hasHeader={hasHeader}
      >
        {children}
      </AppLayout>
    )
  ) : (
    <Navigate to="/auth" replace />
  );
};

const AuthRoute: FC<IpropsAuthRoute> = ({ children }) => {
  const token = localStorage.getItem("token");
  return !token ? children : <Navigate to="/" replace />;
};

const SecondAuthRoute: FC<IpropsAuthRoute> = ({ children }) => {
  const token =
    localStorage.getItem("biometricLogin") &&
    !localStorage.getItem("touchToken");

  return token ? children : <Navigate to="/home" replace />;
};

const Router = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route
          path="/auth"
          element={
            <AuthRoute>
              <Auth />
            </AuthRoute>
          }
        />
        <Route
          path="/login"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />
        <Route
          path="/"
          element={
            <SecondAuthRoute>
              <FingerPrint />
            </SecondAuthRoute>
          }
        />
        <Route
          path="/register"
          element={
            <AuthRoute>
              <Register />
            </AuthRoute>
          }
        />
        <Route
          path="/forget-password"
          element={
            <AuthRoute>
              <ForgetPassword />
            </AuthRoute>
          }
        />
        <Route
          path="/renew-password"
          element={
            <AuthRoute>
              <RenewPassword />
            </AuthRoute>
          }
        />
        <Route
          path="/verify-password"
          element={
            <AuthRoute>
              <VerifyPassword />
            </AuthRoute>
          }
        />
        {/* appRoute */}
        <Route
          path="/home"
          element={
            <AppRoute
              title="ارتباط برقرار شد"
              backUrl="none"
              hasBottomMenu={true}
              hasHeader={true}
              fullScreen={false}
            >
              <HomePage />
            </AppRoute>
          }
        />

        <Route
          path="/geo-map/new"
          element={
            <AppRoute
              title="محدوده جغرافیایی"
              backUrl="/geo-list"
              hasBottomMenu={true}
              hasHeader={true}
              fullScreen={false}
            >
              <GeoMapPage />
            </AppRoute>
          }
        />

        <Route
          path="/geo-map/:tracer_id/:id"
          element={
            <AppRoute
              title="محدوده جغرافیایی"
              backUrl="/geo-list"
              hasBottomMenu={true}
              hasHeader={true}
              fullScreen={false}
            >
              <GeoMapEditPage />
            </AppRoute>
          }
        />

        <Route
          path="/geo-list"
          element={
            <AppRoute
              title="لیست محدوده ها"
              backUrl="/"
              hasBottomMenu={true}
              hasHeader={true}
              fullScreen={false}
            >
              <GeoListPage />
            </AppRoute>
          }
        />

        <Route
          path="/trackers"
          element={
            <AppRoute
              title="ردیاب ها"
              backUrl="/"
              hasBottomMenu={true}
              hasHeader={true}
              fullScreen={false}
            >
              <Trackers />
            </AppRoute>
          }
        />
        <Route
          path="/trackers/easy-access"
          element={
            <AppRoute
              title="دسترسی آسان"
              backUrl="/trackers"
              hasBottomMenu={true}
              hasHeader={true}
              fullScreen={false}
            >
              <EasyAccess />
            </AppRoute>
          }
        />
        <Route
          path="/guide"
          element={
            <AppRoute
              title="راهنما ویگو"
              backUrl="/"
              hasBottomMenu={true}
              hasHeader={true}
              fullScreen={false}
            >
              <Guide />
            </AppRoute>
          }
        />

        <Route
          path="/profile/edit"
          element={
            <AppRoute
              title="ویرایش حساب"
              backUrl="/"
              hasBottomMenu={false}
              hasHeader={true}
              fullScreen={false}
            >
              <EditProfile />
            </AppRoute>
          }
        />
        <Route
          path="/profile/edit-password"
          element={
            <AppRoute
              title="تغییر رمز عبور"
              backUrl="/"
              hasBottomMenu={false}
              hasHeader={true}
              fullScreen={false}
            >
              <EditPassword />
            </AppRoute>
          }
        />
        <Route
          path="/profile/periodic-services"
          element={
            <AppRoute
              title="سرویس های دوره ای"
              backUrl="/"
              hasBottomMenu={false}
              hasHeader={true}
              fullScreen={false}
            >
              <PeriodicServices />
            </AppRoute>
          }
        />
        <Route
          path="/profile/support-call"
          element={
            <AppRoute
              title="ارتباط با پشتیبانی"
              backUrl="/"
              hasBottomMenu={false}
              hasHeader={true}
              fullScreen={false}
            >
              <SupportCall />
            </AppRoute>
          }
        />
        <Route
          path="/trackers/message/:id"
          element={
            <AppRoute
              title="پیام های دریافتی از ردیاب"
              backUrl="/trackers"
              hasBottomMenu={true}
              hasHeader={true}
              fullScreen={false}
            >
              <RecieveMessage />
            </AppRoute>
          }
        />

        <Route
          path="/tracker-settings/:id/:type"
          element={
            <AppRoute
              title="تنظیمات ردیاب"
              backUrl="/trackers"
              hasBottomMenu={true}
              hasHeader={true}
              fullScreen={false}
            >
              <TrackerSettings />
            </AppRoute>
          }
        />

        <Route
          path="/profile/app-settings"
          element={
            <AppRoute
              title="تنظیمات نرم افزار"
              backUrl="/"
              hasBottomMenu={true}
              hasHeader={true}
              fullScreen={false}
            >
              <AppSettings />
            </AppRoute>
          }
        />

        <Route
          path="/profile/transaction"
          element={
            <AppRoute
              title="تراکنش های مالی"
              backUrl="/"
              hasBottomMenu={true}
              hasHeader={true}
              fullScreen={false}
            >
              <TransactionList />
            </AppRoute>
          }
        />

        <Route
          path="/profile/renew-sub"
          element={
            <AppRoute
              title="تمدید اشتراک"
              backUrl="/"
              hasBottomMenu={true}
              hasHeader={true}
              fullScreen={false}
            >
              <RenewSubscription />
            </AppRoute>
          }
        />

        <Route
          path="/user-manager"
          element={
            <AppRoute
              title="لیست کاربران"
              backUrl="/"
              hasBottomMenu={true}
              hasHeader={true}
              fullScreen={false}
            >
              <UserManagementPage />
            </AppRoute>
          }
        />

        <Route
          path="*"
          element={
            <AppRoute
              title="صحفه not found"
              backUrl="/not-found"
              hasBottomMenu={true}
              hasHeader={true}
              fullScreen={true}
            >
              <NotFound />
            </AppRoute>
          }
        />
      </Routes>
    </Suspense>
  );
};
export default Router;
