import Lottie from "react-lottie-player";
import lottieJson from "../../assets/lottie/loading.json";

const Loading = () => {
  return (
    <div className="loadingContainer">
      <Lottie loop animationData={lottieJson} play className="loadingLottie" />
    </div>
  );
};

export default Loading;
