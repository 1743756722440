import { isDesktop } from "react-device-detect";
import { useLayoutEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./router/Routes";
import RotatePage from "./components/common/RotatePage";
import { CheckForPwaInstall } from "./utils/installModalPwa/CheckForPwaInstall";
import CustomInstallModal from "./components/common/CustomInstallModal";
import Loading from "./components/common/Loading";
import { ToastContainer } from "react-toastify";

const App = () => {
  

  const [rotated, setRotated] = useState(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const handleRotate = () => {
    if (isDesktop) return;

    if (window.orientation !== 0) {
      setRotated(true);
    } else {
      setRotated(false);
    }
  };

  useLayoutEffect(() => {
    CheckForPwaInstall();
    document.addEventListener("visibilitychange", () => {
      localStorage.removeItem("touchToken");
    });

    window.addEventListener("resize", handleRotate);
    handleRotate();
    return () => {
      window.removeEventListener("resize", handleRotate);
    };
  }, []);

  return (
    <>
      {rotated ? <RotatePage /> : null}
      <CustomInstallModal />
      {!loaded ? (
        <div className="loadingMainPageContainer">
          <Loading />
        </div>
      ) : null}
      <div className="containerApp" onLoad={() => setLoaded(true)}>
        <div className="containerRoute">
          <BrowserRouter>
            <Router />
            <ToastContainer />
          </BrowserRouter>
        </div>
      </div>
    </>
  );
};

export default App;
