import InstallIcon from "../../assets/icons/install-icon.png";

const CustomInstallModal = () => {
  const closeModal = () => {
    let el = document.getElementById("customInstallModal");
    if (el?.style) el.style.display = "none";
  };

  return (
    <div onClick={closeModal} className="pwaInstallBg" id="customInstallModal">
      <div
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          e.stopPropagation()
        }
        className="pwaInstallContainer"
      >
        <div className="pwaInstallTitle">نصب اپلیکیشن</div>
        <div className="pwaInstallContent">
          <img className="pwaInstallImg" alt="install" src={InstallIcon} />
          <div className="pwaInstallTxt">
            <div className="pwaInstallTxtTitle"> E-Wigo pro</div>
            <div>آیا می خواهید این اپلیکیشن را نصب کنید؟</div>
          </div>
        </div>

        <div className="pwaInstallButtonsContainer">
          <div
            onClick={closeModal}
            className="pwaInstallButton pointer"
            id="installApp"
          >
            نصب
          </div>
          <div onClick={closeModal} className="pwaInstallButtonCancel pointer">
            انصراف
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomInstallModal;
