import { isDesktop } from "react-device-detect";

//custom install modal
export const CheckForPwaInstall = () => {
  if (isDesktop) return;

  let deferredPrompt: any;

  window.addEventListener("beforeinstallprompt", (e) => {
    let el = document.getElementById("customInstallModal");
    if (el?.style) el.style.display = "flex";

    deferredPrompt = e;
  });

  const installApp = document.getElementById("installApp");

  if (installApp) {
    installApp.addEventListener("click", async () => {
      if (deferredPrompt !== null) {
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        if (outcome === "accepted") {
          deferredPrompt = null;
        }
      }
    });
  }
};
