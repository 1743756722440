import ErrorRotate from "../../assets/img/rotate.png";
const RotatePage = () => {
  return (
    <div className="rotationControl">
      <div className="RotationContainer">
        <img className="rotationImgStyle" alt="notFound" src={ErrorRotate} />
        <div className="textRotationStyle">
          لطفا دستگاه خود را عمودی کنید ...
        </div>
      </div>
    </div>
  );
};

export default RotatePage;
